import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import NewsletterTeaser from '@components/newsletterTeaser'
import TopicTeaserGrid from '@components/topicTeaserGrid'
import MagazineTeaserGrid from '@components/magazineTeaserGrid'
import PostCarousel from '@components/postCarousel'
import Container from '@objects/container'
import SplitStage from '@components/splitStage'
import useStaticModule from '@hooks/useStaticModule'
import Headline from '@objects/headline'

import MediaTeaser from '@components/mediaTeaser'

const useStyles = makeStyles((theme) => ({
  root: {},
  currentOpinionHeadline: {
    textAlign: 'center',
    marginBottom: theme.spacing(7),
  },
  gallery: {
    marginTop: theme.spacing(15),
    '& ~ &': {
      marginTop: theme.spacing(30),
    },
  },
}))

function RootIndex({ data, pageContext }) {
  const intl = useIntl()
  const classes = useStyles()
  const { getStaticFieldValue, getStaticFieldMedia, getStaticFieldButton } =
    useStaticModule(pageContext.modules)

  const stageLeft = {
    headline: getStaticFieldValue('home.stage', 'home.stage.left.headline'),
    copy: getStaticFieldValue('home.stage', 'home.stage.left.copy'),
    image: getStaticFieldMedia('home.stage', 'home.stage.left.image'),
    button: getStaticFieldButton('home.stage', 'home.stage.left.button'),
  }

  const stageRight = {
    headline: getStaticFieldValue('home.stage', 'home.stage.right.headline'),
    copy: getStaticFieldValue('home.stage', 'home.stage.right.copy'),
    image: getStaticFieldMedia('home.stage', 'home.stage.right.image'),
    button: getStaticFieldButton('home.stage', 'home.stage.right.button'),
  }

  const topics = data.contentfulTopicCategoryList?.categories?.map((topic) => {
    return {
      headline: topic.title,
      copy: topic.stageCopy?.childMarkdownRemark?.html,
      image: topic.teaserImageWithFocalpoint
        ? topic.teaserImageWithFocalpoint.image.fluid
        : topic.teaserImage?.fluid,
      imageAlt: topic.teaserImageWithFocalpoint
        ? topic.teaserImageWithFocalpoint.image.description
        : topic.teaserImage?.description,
      focalPoint: topic.teaserImageWithFocalpoint?.focalPoint,
      slug: `${intl.formatMessage({ id: 'inform.path' })}${topic.slug}/`,
    }
  })

  let magazineArticles = []
  if (!!data.magazineList) {
    data.magazineList?.categories?.forEach((cat, index) => {
      const firstArticle = cat.nextLevel[0]
      magazineArticles.push({
        createdAt: firstArticle.date ?? firstArticle.createdAt,
        readingTime: firstArticle.readingTime,
        subline: cat.title,
        headline: firstArticle.title,
        copy: firstArticle.teaserCopy?.childMarkdownRemark?.html,
        image: firstArticle.teaserImageWithFocalpoint
          ? firstArticle.teaserImageWithFocalpoint.image.fluid
          : firstArticle.teaserImage?.fluid,
        imageAlt: firstArticle.teaserImageWithFocalpoint
          ? firstArticle.teaserImageWithFocalpoint.image.description
          : firstArticle.teaserImage?.description,
        focalPoint: firstArticle.teaserImageWithFocalpoint?.focalPoint,
        slug: `${intl.formatMessage({ id: 'magazine.path' })}${
          firstArticle.slug
        }/`,
      })
    })
  }

  return (
    <>
      <SplitStage
        type={'split'}
        stageLeft={stageLeft}
        stageRight={stageRight}
      />

      <Container ariaLabel="arialabel.topicteasergrid">
        <TopicTeaserGrid
          headline={data.contentfulTopicCategoryList?.title}
          topics={topics}
          gridMap={[6, 3, 3, 3, 3, 3, 3]}
        />
      </Container>

      <Container ariaLabel="arialabel.currentopinions">
        <Headline className={classes.currentOpinionHeadline} level={2}>
          <FormattedMessage id={'page.home.currentopinions.headline'} />
        </Headline>
        <PostCarousel />
      </Container>

      {!!data.contentfulMediaList && (
        <MediaTeaser
          className={classes.gallery}
          title="Aktuelle Medien zu 5G"
          mediaTeaserList={data.contentfulMediaList?.files}
          button={intl.formatMessage({ id: 'media.path' })}
        />
      )}

      <Container width="md" ariaLabel="arialabel.magazineteasergrid">
        <MagazineTeaserGrid
          headline={data.magazineList?.title}
          articles={magazineArticles}
          button={{
            to: intl.formatMessage({ id: 'magazine.path' }),
            label: intl.formatMessage({ id: 'button.magazine' }),
          }}
        />
      </Container>

      <NewsletterTeaser
        title={intl.formatMessage({ id: 'newsletter.hl' })}
        copy={intl.formatMessage({ id: 'newsletter.copy' })}
      />
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default RootIndex

export const pageQuery = graphql`
  query HomePageQuery($locale: String!) {
    contentfulTopicCategoryList(
      identifier: { eq: "topiccategories" }
      node_locale: { eq: $locale }
    ) {
      title
      categories {
        title
        slug
        stageCopy {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          fluid(maxWidth: 704, quality: 90) {
            ...GatsbyContentfulFluid_noBase64
          }
          description
        }
        teaserImageWithFocalpoint {
          image {
            fluid(maxWidth: 704, quality: 90) {
              ...GatsbyContentfulFluid_noBase64
            }
            description
          }
          focalPoint
        }
      }
    }

    magazineList: contentfulTopicCategoryList(
      identifier: { eq: "homemagazin" }
      node_locale: { eq: $locale }
    ) {
      title
      categories {
        title
        nextLevel {
          ... on ContentfulArticle {
            __typename
            createdAt
            date
            title
            slug
            teaserImage {
              fluid(maxWidth: 704, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            teaserImageWithFocalpoint {
              image {
                fluid(maxWidth: 704, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              focalPoint
            }
            teaserCopy {
              childMarkdownRemark {
                html
              }
            }
            readingTime
          }
        }
      }
    }

    allContentfulMediaListCategory(filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        title
        icon
        identifier
        order
        mediaLists {
          id
          title
          thumbnail {
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          thumbnailWithFocalPoint {
            image {
              fluid(quality: 80, maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              description
            }
            focalPoint
          }
          files {
            __typename
            ... on ContentfulImage {
              id
              title
              trackingTitle
              createdAt
              image {
                description
                file {
                  details {
                    image {
                      height
                      width
                    }
                    size
                  }
                  contentType
                  url
                }
                fluid(quality: 80, maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              intro {
                intro
              }
            }
            ... on ContentfulVideo {
              id
              title
              createdAt
              thumbnail {
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
                fluid(quality: 80, maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              media {
                description
                file {
                  url
                }
              }
              boxCopy {
                boxCopy
              }
            }
            ... on ContentfulYoutube {
              id
              title
              youTubeKey
              createdAt
              thumbnail {
                description
                file {
                  details {
                    image {
                      height
                      width
                    }
                  }
                  url
                }
                fluid(quality: 80, maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              intro {
                intro
              }
            }
            ... on ContentfulPrintMaterial {
              id
              title
              trackingTitle
              createdAt
              subtitle
              copy {
                copy
              }
              images {
                id
                title
                description
                fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              variants {
                id
                variantName
                variantFile {
                  file {
                    details {
                      size
                    }
                    contentType
                    url
                  }
                }
              }
              isOnlyDownload
            }
          }
        }
      }
    }

    contentfulMediaList(
      title: { eq: "Aktuelle Medien zu 5G" }
      node_locale: { eq: "de" }
    ) {
      id
      title
      files {
        __typename
        ... on ContentfulImage {
          id
          title
          trackingTitle
          createdAt
          updatedAt
          image {
            description
            file {
              details {
                image {
                  height
                  width
                }
                size
              }
              contentType
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          intro {
            intro
          }
        }
        ... on ContentfulVideo {
          id
          title
          createdAt
          updatedAt
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          media {
            description
            file {
              url
            }
          }
          boxCopy {
            boxCopy
          }
        }
        ... on ContentfulYoutube {
          id
          title
          youTubeKey
          createdAt
          updatedAt
          thumbnail {
            description
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          intro {
            intro
          }
        }
        ... on ContentfulPrintMaterial {
          id
          title
          trackingTitle
          createdAt
          updatedAt
          subtitle
          copy {
            copy
          }
          images {
            id
            title
            description
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          variants {
            id
            variantName
            variantFile {
              file {
                details {
                  size
                }
                contentType
                url
              }
            }
          }
          isOnlyDownload
        }
      }
    }
  }
`
