import React, { useEffect, useState } from 'react'
import PostCard from '@components/postCard'
import Carousel from '@objects/carousel'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'
import clsx from 'clsx'
import api from '@api'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .swiper-slide': {
      alignSelf: 'center',
    },
  },
  buttonContainer: {
    margin: 'auto',
    textAlign: 'center',
  },
  carouselRoot: {
    '&.swiper-container': {
      marginLeft: '-16px',
      marginRight: '-16px',
      paddingTop: theme.spacing(8),
    },
  },
  postRoot: {
    margin: 'auto',
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',

    width: '88vw',
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      marginLeft: '-20px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '33vw',
      marginLeft: '-10px',
    },
  },
  postCard: {
    width: '100%',
  },
  postAnswerSingle: {
    marginLeft: theme.spacing(8),
    marginRight: theme.spacing(10),
    marginTop: theme.spacing(-3),
  },
  postAnswerDouble: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(15),
    marginTop: theme.spacing(-3),
  },
  button: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  emptySpacer: {
    width: '100%',
    marginBottom: theme.spacing(30),
  },
  placeholder: {
    marginTop: '100px',
  },
}))

function PostCarousel({ ...props }) {
  const intl = useIntl()

  const [posts, setPosts] = useState([])

  useEffect(() => {
    const postRequest = api().getPosts({
      limit: 5,
      overviewModul: true,
    })
    postRequest &&
      postRequest
        .then((response) => {
          setPosts(response.data.result)
        })
        .catch((err) => {
          console.error('posts error', err)
        })
  }, [])

  const theme = useTheme()

  const classes = useStyles()

  function getAnswerBorder(answers) {
    if (answers > 0) {
      return answers > 1 ? 'double' : 'single'
    }
    return 'none'
  }
  return (
    <div className={classes.root}>
      {(posts.length && (
        <Carousel
          carouselKey={'postcarousel'}
          carouselId="postCarousel"
          navigationOpts={{
            disabled: !(posts.length > 1),
            centeredArrows: true,
          }}
          settings={{
            breakpoints: {
              slidesPerView: 1,
              [theme.breakpoints.values.md]: {
                slidesPerView: 3,
                spaceBetween: 20,
                slidesOffsetBefore: -70,
              },
              [theme.breakpoints.values.lg]: {
                slidesPerView: 3,
                spaceBetween: 20,
                slidesOffsetBefore: null,
              },
            },
            centeredSlides: true,
            parallax: true,
            watchOverflow: true,
            direction: 'horizontal',
          }}
          type={'slideFade'}
          className={clsx(classes.carouselRoot, 'post-carousel')}
        >
          {posts.map((item) => {
            const answerBorder = getAnswerBorder(
              item.numberOfUserAnswers + item.numberOfExpertAnswers
            )
            const questionComponent = (
              <PostCard
                className={clsx(classes.postQuestion, classes.postCard)}
                key={_.uniqueId('carousel-post-item-question-')}
                data-test={_.uniqueId('carousel-post-item-question-')}
                postData={{
                  likeCount: item.numberOfLikes,
                  replyCount:
                    item.numberOfUserAnswers + item.numberOfExpertAnswers,
                  userName: item.userName,
                  userColor: item.userColor,
                  timestamp: item.timestamp,
                  isDeleted: item.isDeleted,
                  deleteType: item.deleteType,
                  topics: item.topics,
                  title: item.title,
                  text: item.text,
                  id: item.id,
                }}
                isWrapped={true}
                answerBorder={answerBorder}
                noMetaBar={false}
                textLink={`${intl.formatMessage(
                  { id: 'forum.path.single' },
                  { postId: item.id }
                )}?dialogueid=${item.id}`}
                type={'dialogue'}
              />
            )
            const postAnswer = item.answers ? item.answers[0] : null
            const answerComponent = postAnswer ? (
              <PostCard
                key={_.uniqueId('carousel-post-item-answer-')}
                className={clsx({
                  [classes.postAnswerSingle]: answerBorder === 'single',
                  [classes.postAnswerDouble]: answerBorder === 'double',
                })}
                bar={'yellow'}
                background={'darkSmall'}
                postData={{
                  likeCount: postAnswer.numberOfLikes,
                  replyCount: postAnswer.comments.length,
                  userName: postAnswer.userName,
                  userColor: postAnswer.userColor,
                  timestamp: postAnswer.timestamp,
                  isDeleted: postAnswer.isDeleted,
                  topics: postAnswer.topics,
                  title: postAnswer.title,
                  text: postAnswer.text,
                  isExpert: postAnswer.isExpertAnswer,
                  id: postAnswer.id,
                  comments: postAnswer.comments,
                }}
                isWrapped={true}
                noMetaBar={true}
                textLink={`${intl.formatMessage(
                  { id: 'forum.path.single' },
                  { postId: item.id }
                )}?answerid=${postAnswer.id}`}
                type={'dialogue-answer'}
                interactive={false}
              />
            ) : null
            return (
              <div
                data-swiper-parallax-scale={'0.8'}
                data-swiper-parallax-x={'-30%'}
                className={clsx(classes.postRoot)}
                key={_.uniqueId('carousel-post-item-')}
              >
                {questionComponent}
                {postAnswer && answerComponent}
              </div>
            )
          })}
        </Carousel>
      )) || (
        <div className={classes.carouselRoot}>
          <PostCard
            key="loading-card"
            className={clsx(classes.postRoot, classes.placeholder)}
            postData={{}}
            loading={true}
          />
          <div key="loading-spacer" className={classes.emptySpacer} />
        </div>
      )}
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          color={'red'}
          to={intl.formatMessage({ id: 'dialog.path' })}
        >
          <FormattedMessage id={'button.onlinedialog'} />
        </Button>
        <CreateContentButton className={classes.button} color={'outline'}>
          <FormattedMessage id={'button.createArticle'} />
        </CreateContentButton>
      </div>
    </div>
  )
}

PostCarousel.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
}

export default PostCarousel
