import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import Headline from '@objects/headline'
import Card from '@objects/card'
import Button from '@objects/button'
import ParallaxImage from '@objects/parallaxImage'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    overflow: 'hidden',
  },
  root: {
    marginBottom: theme.spacing(25),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(25),
    },
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(38),
    },
  },
  stageHalf: {
    position: 'relative',
  },
  stageLeft: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
    '& $splitCard': {
      left: 0,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: theme.spacing(25),
    },
    [theme.breakpoints.up('md')]: {
      margin: 0,
      '&$inactiveHalf': {
        '& $splitCard': {
          transform: 'translateX(-100%)',
        },
      },
    },
  },
  stageRight: {
    '& $splitCard': {
      right: 0,
    },
    [theme.breakpoints.up('md')]: {
      '&$inactiveHalf': {
        '& $splitCard': {
          transform: 'translateX(100%)',
        },
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  inactiveHalf: {
    [theme.breakpoints.up('md')]: {
      opacity: 0.2,
      '& img': {
        filter: 'grayscale(100%)',
      },
    },
  },
  splitImage: {
    width: '100%',
    height: '500px',
    [theme.breakpoints.up('lg')]: {
      height: '80vh',
    },
  },
  splitCard: {
    textAlign: 'center',
    opacity: 1,
    transition: 'all 0.3s ease',
    margin: 0,
    width: '95vw',
    [theme.breakpoints.up('sm')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: '45vw',
      bottom: theme.spacing(-10),
    },
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(-23),
    },
    [theme.breakpoints.up('xl')]: {
      width: '35vw',
      bottom: theme.spacing(-10),
    },
    [theme.breakpoints.up('xxl')]: {
      width: '30vw',
    },
  },
  headline: {
    marginBottom: theme.spacing(6),
  },
  subHeadline: {
    ...theme.typography.navigation,
    marginBottom: theme.spacing(6),
  },
}))

function SplitStage({ className, children, ...props }) {
  const classes = useStyles()
const intl = useIntl()
  const LeftRef = useRef(null)
  const RightRef = useRef(null)
  const [unfocusLeft, setUnFocusLeft] = useState(false)
  const [unfocusRight, setUnFocusRight] = useState(false)

  const { stageLeft, stageRight } = props

  const focusStage = (target) => {
    switch (target) {
      case 'left':
        setUnFocusRight(true)
        break
      case 'right':
        setUnFocusLeft(true)
        break
      default:
        setUnFocusLeft(false)
        setUnFocusRight(false)
    }
  }

  return (
    <div className={classes.wrapper} role='region' aria-label={intl.formatMessage({id: 'arialabel.splitstage'})}>
      <Grid container className={classes.root} spacing={8}>
        <Grid item xs={12} md={6} aria-label={intl.formatMessage({id: 'arialabel.splitstage.left'})}>
          <div
            className={clsx(classes.stageHalf, classes.stageLeft, {
              [classes.inactiveHalf]: unfocusLeft,
            })}
            ref={LeftRef}
            onMouseEnter={() => {
              focusStage('left')
            }}
            onMouseLeave={() => {
              focusStage(false)
            }}
          >
            <Hidden smDown>
              <ParallaxImage
                className={classes.splitImage}
                image={stageLeft.image?.fluid || {}}
                alt={stageLeft.image?.description || ''}
              />
            </Hidden>
            <Card
              className={classes.splitCard}
              barOrientation={'right'}
              bind={'split'}
              stage
            >
              <Headline
                className={classes.headline}
                level={1}
                html={stageLeft.headline}
              />
              <p className={classes.subHeadline}>{stageLeft.copy}</p>
              {stageLeft.button && (
                <Button className={''} link={stageLeft.button.link}>
                  {stageLeft.button.label}
                </Button>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6} aria-label={intl.formatMessage({id: 'arialabel.splitstage.right'})}>
          <div
            className={clsx(classes.stageHalf, classes.stageRight, {
              [classes.inactiveHalf]: unfocusRight,
            })}
            ref={RightRef}
            onMouseEnter={() => {
              focusStage('right')
            }}
            onMouseLeave={() => {
              focusStage(false)
            }}
          >
            <Hidden smDown>
              <ParallaxImage
                className={classes.splitImage}
                image={stageRight.image?.fluid || {}}
                alt={stageRight.image?.description || ''}
              />
            </Hidden>
            <Card
              className={clsx(classes.splitCard, classes.splitCardRight)}
              barOrientation={'left'}
              bind={'split'}
              bar={'red'}
              stage
            >
              <Headline
                className={classes.headline}
                level={1}
                html={stageRight.headline}
              />
              <p className={classes.subHeadline}>{stageRight.copy}</p>
              {stageRight.button && (
                <Button
                  className={''}
                  link={stageRight.button.link}
                  color={'red'}
                >
                  {stageRight.button.label}
                </Button>
              )}
            </Card>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

SplitStage.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  stageLeft: PropTypes.object,
  stageRight: PropTypes.object,
}

export default SplitStage
